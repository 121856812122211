import {GarudaApi} from "../../infrastructure/garudaApi";
import {DummyTooltipModule} from "../dummy-tooltip-module";
import {Modal} from "../modal-module";
import {ToolEnum} from "../../models/enums/toolEnum";
import {SubToolEnum} from "../../models/enums/subToolEnum";

export class RespondentMiniProfileModalModule {
    private readonly garudaApi: GarudaApi;
    private readonly miniProfileModal: Modal;

    constructor(
        triggerSelector: string,
        modalId: string,
        getProfileId: (clickedElement: JQuery<HTMLElement>) => string,
        toolEnum?: ToolEnum,
        subTool?: SubToolEnum,
        initializedCallback?: () => void
    ) {
        this.garudaApi = new GarudaApi();

        const getContent = this.determineGetContentFunction(toolEnum, subTool, getProfileId);

        const onInitialized = () => {
            new DummyTooltipModule().init();
            if (initializedCallback) {
                initializedCallback();
            }
        };
        const onNavigate = (url: string) => {window.location.href = url;};

        this.miniProfileModal = new Modal(
            triggerSelector,
            modalId,
            getContent,
            onInitialized,
            onNavigate
        );
    }

    private determineGetContentFunction(toolEnum: ToolEnum | undefined, subTool: SubToolEnum | undefined, getProfileId: (clickedElement: JQuery<HTMLElement>) => string):
        (triggerElement: JQuery<HTMLElement>) => any {
        if (toolEnum === ToolEnum.FocusProfile && subTool === SubToolEnum.FocusPerson || subTool === SubToolEnum.FocusJob || subTool === SubToolEnum.FocusDreamPosition) {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureModels(getProfileId(triggerElement));
        }
        else if (toolEnum === ToolEnum.BasisProfile && subTool === SubToolEnum.BasisPerson) {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureModels(getProfileId(triggerElement));
        }
        else if (toolEnum === ToolEnum.ESportProfile && subTool === SubToolEnum.ESportPerson) {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureModels(getProfileId(triggerElement));
        }
        else if (toolEnum === ToolEnum.FootballProfile && subTool === SubToolEnum.FootballPerson) {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureModels(getProfileId(triggerElement));
        }
        else if (toolEnum === ToolEnum.GolfProfile && subTool === SubToolEnum.GolfPerson) {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureModels(getProfileId(triggerElement));
        }
        else if (toolEnum === ToolEnum.HandballProfile && subTool === SubToolEnum.HandballPerson) {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureModels(getProfileId(triggerElement));
        }
        else {
            return (triggerElement: JQuery<HTMLElement>) => this.garudaApi.getMiniatureGraphs(getProfileId(triggerElement));
        }
    }

    public initMiniProfileModal() {
        this.miniProfileModal.init();
    }
}
