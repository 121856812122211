import * as React from 'react';
import { SelectItemModel } from '../../models/SelectItemModel';

interface AutocompleteInputProps {
    value: string;
    onChange: (value: string) => void;
    onSelect: (value: string) => void;
    onClear: () => void;
    placeholder: string;
    suggestions: SelectItemModel[];
    maxLength?: number;
}

interface AutocompleteInputState {
    filteredSuggestions: SelectItemModel[];
    selectedSuggestionIndex: number;
    selectedValue: string | null;
}

export class AutocompleteInput extends React.Component<AutocompleteInputProps, AutocompleteInputState> {
    private readonly autocompleteRef: React.RefObject<HTMLDivElement>;

    constructor(props: AutocompleteInputProps) {
        super(props);
        this.state = {
            filteredSuggestions: [],
            selectedSuggestionIndex: -1,
            selectedValue: null,
        };
        this.autocompleteRef = React.createRef<HTMLDivElement>();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private filterSuggestions(value: string) {
        return this.props.suggestions.filter((option) =>
            option.value !== this.state.selectedValue &&
            ((option.label?.toLowerCase().includes(value.toLowerCase()) || false) ||
                (option.secondaryLabel?.toLowerCase().includes(value.toLowerCase()) || false))
        );
    }

    private handleInputChangeOrFocus = (value: string) => {
        const filteredSuggestions = this.filterSuggestions(value);
        this.setState({ filteredSuggestions });
        this.props.onChange(value);
    }

    private handleClear = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        this.setState({
            filteredSuggestions: [],
            selectedValue: null
        });
        this.props.onClear();
    }

    private handleClickSuggestion = (value: string) => {
        this.setState({
            selectedValue: value,
            filteredSuggestions: []
        });
        this.props.onSelect(value);
    }

    private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { filteredSuggestions, selectedSuggestionIndex } = this.state;
        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                if (selectedSuggestionIndex > 0) {
                    this.setState({ selectedSuggestionIndex: selectedSuggestionIndex - 1 },
                        this.scrollSelectedIntoView);
                }
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (selectedSuggestionIndex < filteredSuggestions.length - 1) {
                    this.setState({ selectedSuggestionIndex: selectedSuggestionIndex + 1 },
                        this.scrollSelectedIntoView);
                }
                break;
            case 'Enter':
                e.preventDefault();
                if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < filteredSuggestions.length) {
                    this.handleClickSuggestion(filteredSuggestions[selectedSuggestionIndex].value);
                }
                break;
        }
    }

    private handleClickOutside = (event: MouseEvent) => {
        if (this.autocompleteRef.current && !this.autocompleteRef.current.contains(event.target as Node)) {
            this.setState({ filteredSuggestions: [] });
        }
    }

    private scrollSelectedIntoView = () => {
        const selectedElement = this.autocompleteRef.current?.querySelector('.autocomplete__suggestion-item--selected');
        if (selectedElement) {
            selectedElement.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
        }
    }

    render() {
        return (
            <div className="autocomplete" ref={this.autocompleteRef}>
                <div className="autocomplete__input-container">
                    <input
                        className="autocomplete__input form-control"
                        onChange={(e) => this.handleInputChangeOrFocus(e.target.value)}
                        onFocus={(e) => this.handleInputChangeOrFocus(e.target.value)}
                        onKeyDown={this.handleKeyDown}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        maxLength={this.props.maxLength}
                    />
                    {this.props.value && (
                        <div
                            className="autocomplete__clear-button"
                            onClick={this.handleClear}
                            aria-label="Clear input"
                        >
                            <svg className="embla-icon" aria-hidden="true" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <use xlinkHref="/dist/icons/sprite.symbol.svg#remove"></use>
                            </svg>
                        </div>
                    )}
                </div>

                {this.state.filteredSuggestions.length > 0 && (
                    <ul className="autocomplete__suggestions">
                        {this.state.filteredSuggestions.map((option, index) => (
                            <li
                                key={option.value}
                                onClick={() => this.handleClickSuggestion(option.value)}
                                className={`autocomplete__suggestion-item ${index === this.state.selectedSuggestionIndex ? 'autocomplete__suggestion-item--selected' : ''}`}
                            >
                                <div className="autocomplete__suggestion-content">
                                    <p className="autocomplete__suggestion-label">{option.label}</p>
                                    <span className="autocomplete__suggestion-secondary">{option.secondaryLabel}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}
