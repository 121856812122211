import * as React from "react";
import {Localization} from "../../modules/localization-module";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {SelectItemModel} from "../../models/SelectItemModel";
import {RespondentCrudHelper} from "../../models/profileCrud/RespondentCrudHelper";
import {Select2Component} from "../ui-components/Select2Component";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {DatePickerComponent} from "../ui-components/DatePickerComponent";
import {DatetimePickerTypeEnum} from "ditmer-embla";
import {RespondentExistingModel} from "../../models/profileCrud/RespondentExistingModel";
import {TagModuleComponent} from "../../modules/tag-module";
import {AutocompleteInput} from "./AutocompleteInput";

interface IRespondentExtendedDataComponentProps {
    setValue: (key: string, val: string | string[]) => void;
    setRespondentType: (value: number) => void;
    setDistributionValues: (key: string, val: string | string[]) => void;
    existingRespondents: RespondentExistingModel[];
    respondentTypeEnumModels?: SelectItemModel[];
    respondent: RespondentModel;
    subtool?: SubToolEnum;
    setExisting: (respondentId: string) => void;
    setRespondentValues: (respondent: RespondentExistingModel) => void;
    setNewRespondentTab: () => void;
    getExistingRespondentAsSelect: () => SelectItemModel[];
}

export class RespondentExtendedDataComponent extends React.Component<IRespondentExtendedDataComponentProps> {
    private readonly respondentCrudHelper: RespondentCrudHelper;
    private readonly defaultCountryCode: string;
    private readonly defaultTags: SelectItemModel[];

    constructor(props: IRespondentExtendedDataComponentProps) {
        super(props);
        this.respondentCrudHelper = new RespondentCrudHelper();
        this.defaultCountryCode = this.props.respondent.countryCode;
    }

    private setValue(key: string, val: string | string[]) {
        this.props.setValue(key, val);
    }

    private setName(value: string) {
        this.props.setValue("name", value);
        this.props.setDistributionValues("name", value);
    }

    private setEmail(value: string) {
        this.props.setValue("email", value);
        this.props.setDistributionValues("email", value);
    }

    private setPhone(value: string) {
        this.props.setValue("phone", value);
        this.props.setDistributionValues("phone", value);
    }

    private setCountryCode(value: string) {
        this.props.setValue("countryCode", value);
        this.props.setDistributionValues("countryCode", value);
    }

    private onAddItems(selectedTags: string[]) {
        this.setValue("selectedTags", selectedTags);
    }

    private validateCountryCode(keyDownEvent: any) {
        if (
            !parseInt(keyDownEvent.key) &&
            keyDownEvent.keyCode !== 8 &&
            keyDownEvent.keyCode !== 46 &&
            keyDownEvent.keyCode !== 9
        ) {
            keyDownEvent.preventDefault();
        }
    }

    private handleClickSuggestion = (value: string) => {
        const respondent = this.props.existingRespondents.find((respondent: any) => respondent.id === value);
        if (respondent) {
            this.props.setExisting(value);
        } else {
            this.resetForm();
            this.setName(value);
        }
    };

    private resetForm() {
        this.setCountryCode(this.defaultCountryCode);
        this.props.respondent.tags = this.defaultTags;
        this.props.setExisting(null);
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="Name">
                                {Localization.getText("PersonalProfile_Respondent_Name")}
                                <span className="required-field">∗</span>
                            </label>
                            <AutocompleteInput
                                value={this.props.respondent.name ?? ""}
                                onChange={(value) => this.setName(value)}
                                onSelect={this.handleClickSuggestion}
                                onClear={() => this.resetForm()}
                                placeholder={Localization.getText("PersonalProfile_Respondent_Name_Placeholder")}
                                suggestions={this.props.getExistingRespondentAsSelect()}
                                maxLength={90}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor={"Email"}>
                                {Localization.getText("GENERAL_EMAIL")}
                                <span className="required-field">∗</span>
                            </label>
                            <form>
                                <input
                                    name="Email"
                                    className="form-control"
                                    onChange={(e) => this.setEmail(e.target.value)}
                                    value={this.props.respondent.email ?? ""}
                                    placeholder={Localization.getText("PersonalProfile_Respondent_Email_Placeholder")}
                                />
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row margin-top-s">
                    <div className="col-3">
                        <div className="form-group">
                            <label
                                htmlFor={"CountryCode"}>{Localization.getText("PersonalProfile_Respondent_CountryCode")}</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">+</div>
                                </div>
                                <input
                                    name="CountryCode"
                                    type="number"
                                    className="form-control"
                                    id="iconLeftInput"
                                    onKeyDown={(e) => this.validateCountryCode(e)}
                                    onChange={(e) => this.setCountryCode(e.target.value)}
                                    value={this.props.respondent.countryCode}
                                    placeholder={Localization.getText("PersonalProfile_Respondent_CountryCode_Placeholder")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-9">
                        <div className="form-group">
                            <label htmlFor="Phone">{Localization.getText("PersonalProfile_Respondent_Phone")}</label>
                            <input
                                name="Phone"
                                type="text"
                                className="form-control"
                                onChange={(e) => this.setPhone(e.target.value)}
                                value={this.props.respondent.phone ?? ""}
                                placeholder={Localization.getText("PersonalProfile_Respondent_Phone_Placeholder")}
                            />
                        </div>
                    </div>
                </div>

                <div className="row margin-top-m">
                    {this.props.respondentTypeEnumModels != null &&
                        this.props.subtool != null &&
                        this.respondentCrudHelper.canSetRespondentType(this.props.subtool) && (
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="Tags">{Localization.getText("Respondent_Type")}</label>
                                    <Select2Component
                                        multiple={false}
                                        options={this.props.respondentTypeEnumModels}
                                        select2Id="respondent-type-select"
                                        itemSelected={(value) => this.props.setRespondentType(parseInt(value))}
                                        itemUnselected={() => null}
                                        additionalSelect2Options={{tags: true}}
                                    />
                                </div>
                            </div>
                        )}

                    {this.props.subtool != null && this.respondentCrudHelper.canSetExpiryDate(this.props.subtool) && (
                        <div className="col-sm-8">
                            <DatePickerComponent
                                calenderType={DatetimePickerTypeEnum.Date}
                                label={Localization.getText("Global_Expiration")}
                                disabled={false}
                                dateSelected={(key, value) => this.props.setValue("ExpiryDate", value)}
                                settingkey={"expiry-date"}
                                value={this.props.respondent.expiryDate}
                                allowClear={false}
                            />
                        </div>
                    )}

                    <div className="col-sm-12 margin-top-m">
                        <div className="form-group">
                            <label htmlFor="Tags">{Localization.getText("Respondent_Tags")}</label>
                            <TagModuleComponent
                                key={this.props.respondent.id}
                                select2Id={"respondent-tags-select2"}
                                options={this.props.respondent.tags}
                                addTags={(selectedTags) => this.onAddItems(selectedTags)}
                                removeTags={() => null}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
