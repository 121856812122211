import { SelectItemModel } from "../SelectItemModel";

export class RespondentExistingModel {
  constructor() {}

  id: string;
  name: string;
  email: string;
  countryCode: string | null | undefined;
  phone: string | null | undefined;
  tags: SelectItemModel[];
}
