import { GarudaApi } from "../../infrastructure/garudaApi";
import {SubToolEnum} from "../../models/enums/subToolEnum";

declare global {
    interface Window {
        arrowClick: (event: any, ENormal: string, profileId: string, trait: number, addScore: number, level: number) => void;
    }
}

interface ISvgClickProps {
    showGraphsCallback: () => void;
    subtool: SubToolEnum;
}

export class SvgClickModule {

    private readonly svgProps: ISvgClickProps;
    private readonly garudaApi: GarudaApi;

    constructor(svgProps: ISvgClickProps) {
        this.svgProps = svgProps;
        this.garudaApi = new GarudaApi();
    }

    public init() {
        this.arrowClick = this.arrowClick.bind(this);
        window.arrowClick = this.arrowClick;
    }

    private async arrowClick(event: any, ENormal: string, profileId: string, trait: number, addScore: number, level: number) {
        await this.garudaApi.patchSvgClicked(profileId, ENormal, trait, addScore, level, this.svgProps.subtool);
        this.svgProps.showGraphsCallback();
    }
}
