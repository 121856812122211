import * as React from "react";
import {Localization} from "../../modules/localization-module";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {SelectItemModel} from "../../models/SelectItemModel";
import {Select2Component} from "../ui-components/Select2Component";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {RespondentExistingModel} from "../../models/profileCrud/RespondentExistingModel";
import {SelectedTabEnum} from "../../models/enums/respondentSelectedTabEnum";
import {RespondentDataComponent} from "./RespondentDataComponent";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {RespondentExtendedDataComponent} from "./RespondentExtendedDataComponent";

interface IRespondentComponentProps {
    setValue: (key: string, val: string | string[]) => void;
    setRespondentType: (value: number) => void;
    setDistributionValues: (key: string, val: string | string[]) => void;
    respondentTypeEnumModels?: SelectItemModel[];
    respondent: RespondentModel;
    subtool?: SubToolEnum;
    existingRespondentsModel?: SelectItemModel[];
    existingRespondents: RespondentExistingModel[];
    setRespondentId?: (value: string) => void
    setSelectedTab: (selected: SelectedTabEnum) => void;
    garudaApi: GarudaApi;
}

export class RespondentComponent extends React.Component<IRespondentComponentProps> {

    private readonly defaultTags: SelectItemModel[];

    constructor(props: IRespondentComponentProps) {
        super(props);

        this.defaultTags = this.props.respondent.tags;

        this.setExisting = this.setExisting.bind(this);
        this.setRespondentValues = this.setRespondentValues.bind(this);
        this.setNewRespondentTab = this.setNewRespondentTab.bind(this);
        this.getExistingRespondentAsSelect = this.getExistingRespondentAsSelect.bind(this);
    }

    private async setExisting(respondentId: string) {
        if (respondentId) {
            this.props.garudaApi.getRespondentById(respondentId).then((respondent) => {
                this.props.setRespondentId(respondent.id);

                const existingRespondent = new RespondentExistingModel();
                existingRespondent.countryCode = respondent.countryCode;
                existingRespondent.email = respondent.email;
                existingRespondent.id = respondent.id;
                existingRespondent.name = respondent.name;
                existingRespondent.phone = respondent.phone;
                existingRespondent.tags = respondent.tags.map(tag => ({
                    label: tag.tagLabel,
                    selected: tag.selected,
                    value: tag.tagKey
                }));

                this.setRespondentValues(existingRespondent);
            });
        } else {
            this.props.setRespondentId(null);
            this.setRespondentValues(null);
            this.props.respondent.tags = this.defaultTags;
        }
    }

    private setRespondentValues(respondent: RespondentExistingModel) {
        if(respondent) {
            this.props.setValue("name", respondent?.name);
            this.props.setValue("email", respondent?.email);
            this.props.setValue("countryCode", respondent?.countryCode);
            this.props.setValue("phone", respondent?.phone);
            this.props.respondent.tags = respondent.tags
        
            this.props.setDistributionValues("email", respondent?.email);
            this.props.setDistributionValues("countryCode", respondent?.countryCode);
            this.props.setDistributionValues("phone", respondent?.phone);
        }
        else {
            this.props.setValue("name", "");
            this.props.setValue("email", "");
            this.props.setValue("phone", "");
        }
    }

    private resetRespondentExisitingTab() {
        this.props.setValue("name", null);
        this.props.setValue("email", null);
        this.props.setValue("countryCode", "40");
        this.props.setValue("phone", null);
        this.props.respondent.tags = this.defaultTags;

        this.props.setDistributionValues("email", null);
        this.props.setDistributionValues("countryCode", null);
        this.props.setDistributionValues("phone", null);
    }

    private setNewRespondentTab() {
        this.props.setSelectedTab(SelectedTabEnum.newRespondent);
        this.setExisting(null);
    }

    private getExistingRespondentAsSelect() : SelectItemModel[] {
        const optionalItem = new SelectItemModel();
        const existing = this.props.existingRespondents.map(r => {
            const selectItemModel: SelectItemModel = {
                value: r.id,
                label: r.name,
                secondaryLabel: r.email,
                selected: false,
            };

            return selectItemModel;
        });

        return [optionalItem].concat(existing);
    }

    render() {

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        {this.props.subtool != null &&
                            <RespondentDataComponent
                                setDistributionValues={(k, v) => this.props.setDistributionValues(k, v)}
                                setValue={(k, v) => this.props.setValue(k, v)}
                                setRespondentType={(value) => this.props.setRespondentType(value)}
                                respondentTypeEnumModels={this.props.respondentTypeEnumModels}
                                respondent={this.props.respondent}
                                subtool={this.props.subtool}
                            />
                        }

                        {this.props.subtool == null &&
                        <>
                            <ul className="nav nav-tabs nav-justified">
                                <li className="nav-item">
                                    <a className="nav-link active"
                                        onClick={() => this.setNewRespondentTab()}
                                       id="select-new-respondent" href={"#new-respondent-tab"}
                                       aria-controls="new-respondent-tab" aria-expanded="true" role="tab"
                                       data-toggle="tab">
                                        {Localization.getText("Respondent_NewRespondent")}
                                    </a>
                                </li>
                                <li className="nav-item active">
                                    <a className="nav-link"
                                       onClick={() => this.props.setSelectedTab(SelectedTabEnum.existingRespondent)}
                                       id="select-person-respondent" href={"#existing-respondent-tab"}
                                       aria-controls="existing-respondent-tab" aria-expanded="false" role="tab"
                                       data-toggle="tab">
                                        {Localization.getText("Respondent_ExistingRespondent")}
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="new-respondent-tab">
                                    <RespondentExtendedDataComponent
                                        setValue={this.props.setValue}
                                        setRespondentType={this.props.setRespondentType}
                                        setDistributionValues={this.props.setDistributionValues}
                                        existingRespondents={this.props.existingRespondents}
                                        respondentTypeEnumModels={this.props.respondentTypeEnumModels}
                                        respondent={this.props.respondent}
                                        subtool={this.props.subtool}
                                        setExisting={this.setExisting}
                                        setRespondentValues={this.setRespondentValues}
                                        setNewRespondentTab={this.setNewRespondentTab}
                                        getExistingRespondentAsSelect={this.getExistingRespondentAsSelect}
                                    />
                                </div>

                                <div role="tabpanel" className="tab-pane" id="existing-respondent-tab">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="ExistingRespondent">
                                                    {Localization.getText("Respondent_FindExistingRespondent")}
                                                </label>
                                                <Select2Component multiple={false}
                                                                  options={this.getExistingRespondentAsSelect()}
                                                                  select2Id="respondent-type-select"
                                                                  allowClear={true}
                                                                  itemSelected={(value) => this.setExisting(value)}
                                                                  itemUnselected={() => this.resetRespondentExisitingTab()}
                                                                  useTemplate={true}
                                                                  allowSecondaryValueSearch={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </>);
    }
}
