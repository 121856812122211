import * as React from "react";
import { Select2Component } from "../../react/ui-components/Select2Component";
import { SelectItemModel } from "../../models/SelectItemModel";

interface ITagModuleProps {
  select2Id: string;
  options: SelectItemModel[];
  addTags: (selectedTags: string[]) => void;
  removeTags: (selectedTags: string[]) => void;
}

interface ITagModuleState {
  options: SelectItemModel[];
  inputValue: string;
}

export class TagModuleComponent extends React.Component<ITagModuleProps, ITagModuleState> {
  private addedTags: string[];

  constructor(props: Readonly<ITagModuleProps>) {
    super(props);

    this.state = {
      inputValue: "",
      options: props.options,
    };

    this.addedTags = props.options.filter((o) => o.selected).map((x) => x.value);
  }

  private handleChange() {
    const selectedTagValues = this.state.options.filter((o) => o.selected).map((x) => x.value);

    if (this.addedTags && this.addedTags.length > 0) {
      const tagsToRemove = this.addedTags.filter((at) => selectedTagValues.indexOf(at) === -1);
      this.props.removeTags(tagsToRemove);
    }

    this.addedTags = selectedTagValues;
    this.props.addTags(selectedTagValues);
  }

  componentDidUpdate(prevProps: ITagModuleProps) {
    if (prevProps.options !== this.props.options) {
      this.setState(
        {
          options: this.props.options,
        },
        () => {
          this.addedTags = this.props.options.filter((o) => o.selected).map((x) => x.value);
          this.handleChange();
        }
      );
    }
  }

  render() {
    return (
      <>
        <Select2Component
          multiple={true}
          options={this.state.options}
          select2Id={this.props.select2Id}
          itemSelected={() => this.handleChange()}
          itemUnselected={() => this.handleChange()}
          additionalSelect2Options={{ tags: true }}
        />
      </>
    );
  }
}
