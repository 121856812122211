// eslint-disable-next-line prefer-const
jQuery = require("jquery");

declare let global: any;
declare let window: any;
declare let jQuery: any;
declare let $: any;

global.jQuery = global.$ = jQuery;
window.jQuery = window.$ = $ = jQuery;

import "bootstrap";

// tslint:disable-next-line:no-var-requires
require("jquery-validation");
// tslint:disable-next-line:no-var-requires
require("jquery-validation-unobtrusive");

import AsyncFormPostModule from "./modules/asyncFormPost";
import AsyncValueUpdateModule from "./modules/asyncValueUpdate";
import ClickableRowsModule from "./modules/clickableRows";
import {ConfirmationModalModule, ConfirmationModalType} from "./modules/confirmationModal";
import DropdownModule from "./modules/dropdown";
import InputStatusModule from "./modules/inputStatus";
import { NotificationModule, NotificationPositionEnum } from "./modules/notification";
import LeftMenuModule from "./modules/leftMenu";
import FileUploadModule from "./modules/fileUpload";
import TooltipModule from "./modules/tooltip";
import { TableModule, FiltersInModalBreakpoint } from "./modules/table";
import { CookieDisclaimerModule } from "./modules/cookieDisclaimer";
import NumericInputModule from "./modules/numericInput";
import { DatetimePickerModule, DatetimePickerTypeEnum } from "./modules/datetimepicker";
import { IMergeFieldModel, MergefieldPlugin, RichTextEditorModule, RichTextEditorPlugin } from "./modules/richTextEditor";
import {StickyFooterModule} from "./modules/stickyFooter";
import {PopoverModule} from "./modules/popover";

/**
 * Initialises embla, should be called in $(document).ready()
 */
const initEmbla = (): void => {
    $(document).on("focus", ".input-group > .form-control", function() {

        const $element = $(this);
        const $parent = $element.parent(".input-group");

        $parent.addClass("focus");

        $element.one("blur", () => {
            $parent.removeClass("focus");
        });
    });
};

export {
    initEmbla,
    AsyncFormPostModule,
    AsyncValueUpdateModule,
    ClickableRowsModule,
    ConfirmationModalModule,
    DropdownModule,
    InputStatusModule,
    NotificationModule,
    NotificationPositionEnum,
    LeftMenuModule,
    FileUploadModule,
    TooltipModule,
    CookieDisclaimerModule,
    TableModule,
    FiltersInModalBreakpoint,
    NumericInputModule,
    DatetimePickerModule,
    DatetimePickerTypeEnum,
    ConfirmationModalType,
    StickyFooterModule,
    RichTextEditorModule,
    MergefieldPlugin,
    IMergeFieldModel,
    RichTextEditorPlugin,
    PopoverModule
};
